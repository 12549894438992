import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-teacher/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { defineComponent, reactive, toRefs } from 'vue';
import loginPwd from './components/login-pwd.vue';
export default defineComponent({
  props: {},
  components: {
    loginPwd: loginPwd
  },
  setup: function setup() {
    var dataMap = reactive({
      loginType: '',
      isPwdLogin: true,
      isMobileLogin: false
    });
    var refData = toRefs(dataMap);

    function handleLoginType(loginType) {
      dataMap.loginType = loginType;

      switch (loginType) {
        case '1':
          dataMap.isPwdLogin = true;
          dataMap.isMobileLogin = false;
          break;

        case '2':
          dataMap.isPwdLogin = false;
          dataMap.isMobileLogin = true;
          break;
      }
    }

    return _objectSpread(_objectSpread({}, refData), {}, {
      handleLoginType: handleLoginType
    });
  }
});
import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-teacher/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {},
  components: {},
  setup: function setup() {
    var store = useStore();
    var dataMap = reactive({
      ruleForm: {
        username: '',
        password: ''
      },
      isShowPwd: false,
      pwdType: 'password',
      eyeImg: require('@/assets/img/login/eyeClose.png')
    });

    function handleEye() {
      dataMap.isShowPwd = !dataMap.isShowPwd;

      if (dataMap.isShowPwd) {
        dataMap.eyeImg = require('@/assets/img/login/eyeOpen.png');
        dataMap.pwdType = 'text';
      } else {
        dataMap.eyeImg = require('@/assets/img/login/eyeClose.png');
        dataMap.pwdType = 'password';
      }
    }

    var onSubmit = function onSubmit(values) {
      store.dispatch('login/accountLoginAction', _objectSpread({}, dataMap.ruleForm));
    };

    var refData = toRefs(dataMap);
    return _objectSpread(_objectSpread({}, refData), {}, {
      handleEye: handleEye,
      onSubmit: onSubmit
    });
  }
});